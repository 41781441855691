import { LayoutKey } from '#build/types/layouts'
import { useRouteLayout } from '~/api/drupal'

export default defineNuxtRouteMiddleware(async (to, from) => {
  // Start loading before the page data is fetched
  const { setLoading } = useLoadingStore()
  if (!process.server && from.path !== to.path) {
    setLoading(true)
  }

  // Set the page layout
  const { layout } = await useRouteLayout(to.path)
  setPageLayout(layout as LayoutKey)
})
